/* eslint-disable react/jsx-no-target-blank */
import SC from './headerElements';

const RewardsTooltip = () => (
  <span className="tooltiptext">
    <SC.Row mb="m" className="tooltip-heading">
      <SC.Col pb="m">
        <h2>Get more of what you love with the FairPrice&nbsp;Group&nbsp;app</h2>
      </SC.Col>
    </SC.Row>
    <SC.Row flexDirection={['column', 'row']}>
      <SC.Box flexBasis={['0', '35%']} p="0" textAlign="right" alignSelf="center">
        <img src="/static/img/pages/home/fpg-app.png" alt="Iphone mockup" width="140" />
      </SC.Box>
      <SC.Col flexBasis={['0', '65%']} className="tooltip-body" flexbox alignSelf="center">
        <ul>
          <li>
            Get Linkpoints everywhere; Go cardless at FairPrice Group & participating Link partners
          </li>
          <li>Turn your Linkpoints into rewards</li>
          <li>Track Linkpoints history on the go</li>
          <li>Be updated on offers from FairPrice Group & Link partners</li>
        </ul>
      </SC.Col>
    </SC.Row>
    <SC.Row justifyContent={['center', 'flex-start']}>
      <SC.StyledCol>
        <a href="https://xdfz.adj.st/accounts?adj_t=f2ug2ph" target="_blank" rel="noreferrer">
          <img alt="App" src="/static/img/common/app-icon.png" width="150" />
        </a>

        <a href="https://xdfz.adj.st/accounts?adj_t=f2ug2ph" target="_blank" rel="noreferrer">
          <img alt="Fairprice app" src="/static/img/common/google-play-icon.png" width="150" />
        </a>
      </SC.StyledCol>
    </SC.Row>
  </span>
);

export default RewardsTooltip;
