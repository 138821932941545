import styled from 'styled-components';
import { flexbox, layout, space, typography } from 'styled-system';

import { theme } from './GlobalStyles';

const Contain = styled.div`
  max-width: 80rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  ${space};
  ${(props) => props.noPadding && 'padding-left: 0; padding-right: 0;'}

  ${theme.mediaQueries.above.small} {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
`;

const Flex = styled.div`
  ${layout}
  ${flexbox}
  ${space}
  ${typography}
  display: flex;
`;

const Box = styled.div`
  ${space}
  ${layout}
  ${flexbox}
  ${typography}
`;

const Row = styled(Flex)`
  margin-left: -1rem;
  margin-right: -1rem;
`;

const Col = styled(Box)`
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 0;
  ${(props) => props.flexbox && 'display: flex;'}
`;

export { Contain, Flex, Box, Row, Col };
