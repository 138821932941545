/* Desktop Nav */
/* eslint-disable react/jsx-no-target-blank */
import Link from 'next/link';

import { useAccountState } from '../context/account-context';

import SC from './headerElements';
import NavToggle from './NavToggle';
import RewardsTooltip from './RewardsTooltip';

const Masthead = ({ navOpen, toggleNav, toggleSearch, searchOpen }) => {
  const { accountData, isLoggedIn, logout } = useAccountState();
  return (
    <SC.StyledMasthead>
      <div className="header-inner">
        <Link href="/" className="logo-link">
          <img alt="NTUC Link" className="logo" src="/static/img/common/fpg50-logo.svg" />
        </Link>
        <NavToggle
          navOpen={navOpen}
          toggleNav={toggleNav}
          toggleSearch={toggleSearch}
          searchOpen={searchOpen}
          className="head-visible-mobile"
        />

        <ul className="meta head-hidden-mobile">
          <SC.StyledLi>
            <button className="tooltip">
              Go Cardless <RewardsTooltip />
            </button>
          </SC.StyledLi>
          <SC.StyledLi>
            <a href={`${process.env.NEXT_PUBLIC_WP_URL}/apply/cards`}>Be a Member</a>
          </SC.StyledLi>
          <SC.StyledLi>
            <a
              href={`${process.env.NEXT_PUBLIC_LINKPASS_ACCOUNT_URL}/linkpoints/cards`}
              target="_blank"
            >
              Replace My Card
            </a>
          </SC.StyledLi>
          {isLoggedIn ? (
            <SC.StyledLi data-loggedin={isLoggedIn}>
              <Link href="/profile">
                {accountData.fname} {accountData.lname}
              </Link>
              <SC.SubNav data-loggedin={isLoggedIn}>
                <a href="#/" onClick={logout}>
                  Logout
                </a>
              </SC.SubNav>
            </SC.StyledLi>
          ) : (
            <SC.StyledLi>
              <Link href="/login">Login</Link>
            </SC.StyledLi>
          )}
          <li className="faq">
            <a className="faq" href="https://support.link.sg/hc/en-us">
              FAQs
            </a>
          </li>
        </ul>
      </div>
    </SC.StyledMasthead>
  );
};

export default Masthead;
