/* Mobile Nav */
/* eslint-disable react/jsx-no-target-blank */
import { memo, useState } from 'react';

import Link from '../ActiveLink';
import AlertDialog from '../AlertDialog';
import { useAccountState } from '../context/account-context';

import SearchIcon from './Search/SearchIcon';
import SC from './headerElements';
import RewardsTooltip from './RewardsTooltip';

const handleSubnavLink = (e) => {
  if (window.innerWidth < 640) {
    e.preventDefault();
    e.stopPropagation();
    e.target.parentElement.classList.toggle('is-active');
  }
};

const NavContent = memo(({ onClick, fname, lname, isLoggedIn, searchOpen, toggleSearch }) => (
  <ul onClick={onClick} className="main-nav">
    <SC.Flex className="styled-flex">
      {isLoggedIn && (
        <li className="head-visible-mobile">
          <Link href="/profile">
            <a className="is-active" style={{ textAlign: 'center', fontSize: 18 }}>
              {fname} {lname}
            </a>
          </Link>
        </li>
      )}
      <li>
        <Link href={`${process.env.NEXT_PUBLIC_WP_URL}/member-benefits`}>
          <a>Explore Member Benefits</a>
        </Link>
      </li>
      <li className="has-subnav">
        <a role="button" href="/all-partners" data-testid="sub-nav" onClick={handleSubnavLink}>
          Shop with Our Partners
        </a>
        <SC.SubNavMobile>
          <ul>
            <li>
              <a href="/all-partners">All Partners</a>
            </li>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/visit-downtown-east`}>Downtown East</a>
            </li>
            <li>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/online-mall`}>Earn Linkpoints Online</a>
            </li>
          </ul>
        </SC.SubNavMobile>
      </li>
      <li>
        <Link href="/exchange">
          <a>Convert Linkpoints</a>
        </Link>
      </li>
    </SC.Flex>
    <SC.Flex>
      <li className="head-hidden-mobile">
        <SearchIcon searchOpen={searchOpen} toggleSearch={toggleSearch} />
      </li>
    </SC.Flex>
  </ul>
));

const Nav = ({ navOpen, toggleNav, searchOpen, toggleSearch }) => {
  const { accountData, isLoggedIn, logout } = useAccountState();
  const [rewardsModalVisible, setRewardsModalVisible] = useState(false);
  return (
    <SC.StyledNavBox>
      <SC.Nav className={navOpen ? 'is-open' : ''}>
        <NavContent
          isLoggedIn={isLoggedIn}
          fname={accountData && accountData.fname}
          lname={accountData && accountData.lname}
          toggleSearch={toggleSearch}
          searchOpen={searchOpen}
        />
        <SC.MobileLogin className="head-visible-mobile">
          <ul className="meta" onClick={toggleNav}>
            <li>
              <a className="faq" href="https://support.link.sg/hc/en-us">
                FAQs
              </a>
            </li>
            <SC.StyledLi data-testid="rewards-app" onClick={() => setRewardsModalVisible(true)}>
              <a className={`${rewardsModalVisible && 'is-active'}`}>Go Cardless</a>
            </SC.StyledLi>
            <SC.StyledLi>
              <a href={`${process.env.NEXT_PUBLIC_WP_URL}/apply/cards`} target="_blank">
                Be a Member
              </a>
            </SC.StyledLi>
            <SC.StyledLi>
              <a href={`${process.env.NEXT_PUBLIC_LINKPASS_ACCOUNT_URL}/linkpoints/cards`}>
                Replace My Card
              </a>
            </SC.StyledLi>
            <SC.StyledLi>
              {isLoggedIn ? (
                <>
                  <a href="#/" onClick={logout}>
                    Logout
                  </a>
                </>
              ) : (
                <Link href="/login">
                  <a>Login</a>
                </Link>
              )}
            </SC.StyledLi>
          </ul>
        </SC.MobileLogin>
      </SC.Nav>
      {rewardsModalVisible && (
        <AlertDialog
          onDismiss={() => {
            setRewardsModalVisible(false);
          }}
          isOpen={rewardsModalVisible}
          className="rewards-modal"
          testid="rewards-modal"
          ariaLabel="Find us on the FairPrice Group app!"
        >
          <RewardsTooltip />
        </AlertDialog>
      )}
    </SC.StyledNavBox>
  );
};

export default Nav;
