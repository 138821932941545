import styled from 'styled-components';

import { theme } from '../GlobalStyles';
import { Box } from '../Grid';

import FooterNav from './FooterNav';

const StyledFooter = styled.footer`
  margin-top: auto;
  padding-top: 3.75rem;

  ${theme.mediaQueries.above.small} {
    .toggle-nav {
      display: none;
    }
    .header-inner {
      height: 5rem;
    }
    .logo {
      height: 3.75rem;
    }
  }
`;

const FooterInner = styled.footer`
  border-top: 1px solid #959595;
  background: #73308a;
  padding-top: 3.75rem;
  background-image: url(/static/img/common/mobile-footer.png);
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-size: auto;
  padding-bottom: 1rem;
  background-size: 100%;
  ${theme.mediaQueries.above.small} {
    background-image: url(/static/img/common/desktop-footer.png);
  }
`;
const SocialMediaLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  padding-top: ${theme.space.xs};
  a {
    width: 2.375rem;
    height: 2.375rem;
    margin: 0 0.5rem;
  }
`;
const StyledBox = styled(Box)`
  display: flex;
  color: white;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem 1rem 0;
  font-size: 1rem;
  font-weight: 400;
  margin: auto;
  max-width: 20rem;
  background: #73308a;

  ${theme.mediaQueries.only.xlarge} {
    padding-bottom: 0.5rem;
  }
  ${theme.mediaQueries.above.xxlarge} {
    padding-bottom: 1.5625rem;
  }
`;
const Footer = () => (
  <StyledFooter data-testid="footer">
    <FooterInner>
      <FooterNav />
      <SocialMediaLinks>
        <a
          target="_blank"
          href="https://www.facebook.com/LinkRewardsSG"
          className="facebook-icon sociaMediaIcon"
          aria-label="Link Facebook"
          rel="noreferrer noopener"
        >
          <img alt="Facebook" src="/static/img/icons/facebook-icon.png" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/linkrewardssg"
          className="instagram-icon sociaMediaIcon"
          aria-label="Link Rewards"
          rel="noreferrer noopener"
        >
          <img alt="Instagram" src="/static/img/icons/instagram-icon.png" />
        </a>

        <a
          target="_blank"
          href="https://www.linkedin.com/company/ntuc-link-pte-ltd"
          className="sociaMediaIcon"
          aria-label="Link LinkedIn"
          rel="noreferrer noopener"
        >
          <img alt="LinkedIn" src="/static/img/icons/linkedin-icon.png" />
        </a>
      </SocialMediaLinks>
      <StyledBox>© {new Date().getFullYear()} NTUC Link. All rights reserved.</StyledBox>
    </FooterInner>
  </StyledFooter>
);

export default Footer;
