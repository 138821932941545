/* eslint-disable jest/no-mocks-import */
/* eslint-disable import/prefer-default-export */
import { get } from '../fetch';

const handleCatchError = (error) => {
  // Keeping this as it will help to throw Promise.reject error
  console.log('Error: ', error);
  throw error;
};

const headers = {
  'Content-Type': 'application/json',
  'X-User-Source': 'link_web',
};

// Get Home Page Details
export const getHomeData = async () => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/v2/home`, {
    headers,
  }).catch(handleCatchError);
};

// Get Featured Partners
export const getFeaturedPartners = async () => {
  return get(
    `${process.env.NEXT_PUBLIC_RAMEN_API}/partners?categoryType=web_featured_partners_page`,
    {
      headers,
    },
  ).catch(handleCatchError);
};

// Get Partners
export const getPartners = async () => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/partners`, {
    headers,
  }).catch(handleCatchError);
};

// Get Partners List
export const getPartnersList = async ({ pageNumber, filterId }) => {
  return get(`/api/partners?pageNumber=${pageNumber}&filterId=${filterId}`).catch(handleCatchError);
};

// Get Partner Details
export const getPartnerDetails = async (slug) => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/partners/${slug}?idType=slug`, {
    headers,
  }).catch(handleCatchError);
};

// Get Promotions
export const getPromotions = async () => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/promotions?sort=-latest`, {
    headers,
  }).catch(handleCatchError);
};

// Get Promotion Details
export const getPromoDetails = async (slug) => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/promotions/${slug}?idType=slug`, {
    headers,
  }).catch(handleCatchError);
};

// Get Search Results
export const getSearchResults = async (query) => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/search?q=${query}`, {
    headers,
  }).catch(handleCatchError);
};

// Get Maintenance Details
export const getMaintenance = async () => {
  return get(`${process.env.NEXT_PUBLIC_RAMEN_API}/maintenance?status=active`, {
    headers,
  }).catch(handleCatchError);
};
